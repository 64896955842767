import { match as about_us } from "../../../src/params/about_us.ts";
import { match as academy } from "../../../src/params/academy.ts";
import { match as blog } from "../../../src/params/blog.ts";
import { match as call_me } from "../../../src/params/call_me.ts";
import { match as charging_station } from "../../../src/params/charging_station.ts";
import { match as cms_preview } from "../../../src/params/cms_preview.ts";
import { match as construction } from "../../../src/params/construction.ts";
import { match as contact } from "../../../src/params/contact.ts";
import { match as cookie_terms } from "../../../src/params/cookie_terms.ts";
import { match as cooperation } from "../../../src/params/cooperation.ts";
import { match as documents } from "../../../src/params/documents.ts";
import { match as done } from "../../../src/params/done.ts";
import { match as expert } from "../../../src/params/expert.ts";
import { match as expert_advice } from "../../../src/params/expert_advice.ts";
import { match as extended_warranty } from "../../../src/params/extended_warranty.ts";
import { match as feedback } from "../../../src/params/feedback.ts";
import { match as filled } from "../../../src/params/filled.ts";
import { match as financing } from "../../../src/params/financing.ts";
import { match as gas_boilers } from "../../../src/params/gas_boilers.ts";
import { match as glossary } from "../../../src/params/glossary.ts";
import { match as grants } from "../../../src/params/grants.ts";
import { match as guarantee } from "../../../src/params/guarantee.ts";
import { match as heat_pump } from "../../../src/params/heat_pump.ts";
import { match as heat_pump_30_50 } from "../../../src/params/heat_pump_30_50.ts";
import { match as job_offers } from "../../../src/params/job_offers.ts";
import { match as leave_contact } from "../../../src/params/leave_contact.ts";
import { match as new_payment } from "../../../src/params/new_payment.ts";
import { match as offer } from "../../../src/params/offer.ts";
import { match as operator } from "../../../src/params/operator.ts";
import { match as paging } from "../../../src/params/paging.ts";
import { match as payment } from "../../../src/params/payment.ts";
import { match as personal_meeting } from "../../../src/params/personal_meeting.ts";
import { match as phone_consultation } from "../../../src/params/phone_consultation.ts";
import { match as photovoltaics } from "../../../src/params/photovoltaics.ts";
import { match as photovoltaics_30_50 } from "../../../src/params/photovoltaics_30_50.ts";
import { match as praise_woltair } from "../../../src/params/praise_woltair.ts";
import { match as privacy } from "../../../src/params/privacy.ts";
import { match as privacy_policy } from "../../../src/params/privacy_policy.ts";
import { match as region } from "../../../src/params/region.ts";
import { match as registered } from "../../../src/params/registered.ts";
import { match as send } from "../../../src/params/send.ts";
import { match as service } from "../../../src/params/service.ts";
import { match as settings } from "../../../src/params/settings.ts";
import { match as thank_you } from "../../../src/params/thank_you.ts";
import { match as thankyou } from "../../../src/params/thankyou.ts";
import { match as woltadvisor } from "../../../src/params/woltadvisor.ts";
import { match as woltair } from "../../../src/params/woltair.ts";
import { match as woltair_self_service } from "../../../src/params/woltair_self_service.ts";

export const matchers = { about_us, academy, blog, call_me, charging_station, cms_preview, construction, contact, cookie_terms, cooperation, documents, done, expert, expert_advice, extended_warranty, feedback, filled, financing, gas_boilers, glossary, grants, guarantee, heat_pump, heat_pump_30_50, job_offers, leave_contact, new_payment, offer, operator, paging, payment, personal_meeting, phone_consultation, photovoltaics, photovoltaics_30_50, praise_woltair, privacy, privacy_policy, region, registered, send, service, settings, thank_you, thankyou, woltadvisor, woltair, woltair_self_service };