import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129')
];

export const server_loads = [0,25,23,24,11,10,7,6,5,4,18,17,15,13,21,9];

export const dictionary = {
		"/(default)/(public)": [~29,[2]],
		"/account-fe/[...rest]": [127,[25]],
		"/healtz": [128],
		"/(default)/(public)/(statics-support)/(cs)/nasim-obchodnikum-muzete-verit": [~116,[2,23]],
		"/(default)/(public)/(statics-support)/(cs)/nova-zelena-usporam": [~117,[2,23,24]],
		"/(default)/(public)/(features-core)/(agreements)/potvrzeni-souhlasu": [~30,[2,3]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-plynovy-kotel-tepelne-cerpadlo": [~118,[2,23]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-tuha-paliva-tepelne-cerpadlo": [~119,[2,23]],
		"/(default)/(public)/(statics-support)/(cs)/prechod-z-elektrokotle-na-tepelne-cerpadlo": [~120,[2,23]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju-electree": [~33,[2,3]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju-electree/odeslano": [~34,[2,3]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju": [~31,[2,3]],
		"/(default)/(public)/(features-core)/(agreements)/predani-udaju/odeslano": [~32,[2,3]],
		"/(default)/(customer)/(redirects)/prihlaseni": [~26],
		"/test-translations": [129],
		"/(default)/(customer)/(redirects)/zakaznik": [~27],
		"/(default)/(customer)/(redirects)/zakaznik/[...url]": [~28],
		"/(default)/(public)/(statics-support)/[photovoltaics_30_50=photovoltaics_30_50]": [~124,[2]],
		"/(default)/(public)/(statics-support)/[heat_pump_30_50=heat_pump_30_50]": [~123,[2]],
		"/(default)/(public)/(statics-support)/[extended_warranty=extended_warranty]": [~122,[2]],
		"/(default)/(public)/(statics-support)/(de)/[woltair_self_service=woltair_self_service]": [~121,[2]],
		"/(default)/(public)/(statics-core)/[praise_woltair=praise_woltair]": [~115,[2]],
		"/(default)/(public)/(statics-core)/[financing=financing]": [~112,[2,22]],
		"/(default)/(public)/(statics-core)/[documents=documents]": [~110,[2]],
		"/(default)/(public)/(statics-core)/[cooperation=cooperation]": [~109,[2]],
		"/(default)/(public)/(statics-core)/[contact=contact]": [~108,[2]],
		"/(default)/(public)/(statics-core)/[about_us=about_us]": [~104,[2]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]": [~88,[2,16]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]": [~75,[2,12]],
		"/(default)/(public)/(product-pages)/[gas_boilers=gas_boilers]": [~74,[2,11]],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]": [~71,[2,10]],
		"/(default)/(public)/(features-support)/[job_offers=job_offers]": [~69,[2]],
		"/(default)/(public)/(features-support)/[glossary=glossary]": [~67,[2]],
		"/(default)/(public)/(features-support)/[cms_preview=cms_preview]": [~63,[2]],
		"/(default)/(public)/(features-support)/[academy=academy]": [~60,[2,8]],
		"/(default)/(public)/(features-core)/[woltadvisor=woltadvisor]": [~59,[2,3]],
		"/(default)/(public)/(features-core)/[service=service]": [~50,[2,3]],
		"/(default)/(public)/(features-core)/[phone_consultation=phone_consultation]": [~49,[2,3]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]": [~46,[2,3,7]],
		"/(default)/(public)/(features-core)/[leave_contact=leave_contact]": [~45,[2,3,6]],
		"/(default)/(public)/(features-core)/[feedback=feedback]": [~38,[2,3,5]],
		"/(default)/(public)/(features-core)/[call_me=call_me]": [~35,[2,3]],
		"/(default)/(public)/(features-core)/[settings=settings]/errcheck": [~56,[2,3]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/kontakt": [~103,[2,16]],
		"/(default)/(public)/(statics-core)/[grants=grants]/kotlikove-dotace/[region]": [~114,[2]],
		"/(default)/(public)/(features-core)/[settings=settings]/ldcheck": [~57,[2,3]],
		"/(default)/(public)/(features-core)/[call_me=call_me]/nzu-light": [~37,[2,3,4]],
		"/(default)/(public)/(features-core)/[service=service]/odeslano": [~52,[2,3]],
		"/(default)/(public)/(features-core)/[service=service]/platba": [~53,[2,3]],
		"/(default)/(public)/(features-core)/[service=service]/platba/zaplaceno": [~54,[2,3]],
		"/(default)/(public)/(features-core)/[settings=settings]/translations": [~58,[2,3]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]": [~97,[2,16,20]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[region=region]": [~95,[2,16]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[phone_consultation=phone_consultation]": [~94,[2,16,19]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[personal_meeting=personal_meeting]": [93,[2,16]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[offer=offer]": [~92,[2,16,18]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[leave_contact=leave_contact]": [~91,[2,16,17]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[financing=financing]": [~89,[2,16]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]": [~85,[2,12,15]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[region=region]": [~83,[2,12]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[phone_consultation=phone_consultation]": [~82,[2,12]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[personal_meeting=personal_meeting]": [81,[2,12]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[offer=offer]": [~80,[2,12,14]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[leave_contact=leave_contact]": [~79,[2,12,13]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[financing=financing]": [~77,[2,12]],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]/[grants=grants]": [~72,[2,10]],
		"/(default)/(public)/(features-core)/[settings=settings]/[privacy=privacy]": [~55,[2,3]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]/[paymemt=payment]": [~48,[2,3,7]],
		"/(default)/(public)/(features-core)/[personal_meeting=personal_meeting]/[new_payment=new_payment]": [~47,[2,3,7]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[woltair=woltair]": [~44,[2,3,5]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[thankyou=thankyou]": [~43,[2,3,5]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[operator=operator]": [~42,[2,3,5]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[filled=filled]": [41,[2,3,5]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[expert=expert]": [~40,[2,3,5]],
		"/(default)/(public)/(features-core)/[feedback=feedback]/[construction=construction]": [~39,[2,3,5]],
		"/(default)/(public)/(features-core)/[call_me=call_me]/[thank_you=thank_you]": [36,[2,3]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]/playground-combined": [~87,[2,12,15]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground": [~98,[2,16,20]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[woltadvisor=woltadvisor]/playground": [~86,[2,12,15]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/energy-invoices": [99,[2,16,20]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/monthly-costs": [100,[2,16,20]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/query-short": [101,[2,16,20]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[woltadvisor=woltadvisor]/playground/sun-and-roof": [~102,[2,16,20]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[grants=grants]/[thank_you=thank_you]": [~90],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[grants=grants]/[thank_you=thank_you]": [~78],
		"/(default)/(public)/(product-pages)/[charging_station=charging_station]/[grants=grants]/[thank_you=thank_you]": [~73,[2,10]],
		"/(default)/(public)/(product-pages)/[photovoltaics=photovoltaics]/[region=region]/[city]": [~96,[2,16]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[region=region]/[city]": [~84,[2,12]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[[paging=paging]]": [~105,[2,21]],
		"/(default)/(public)/(features-support)/[expert_advice=expert_advice]/[[paging=paging]]": [~64,[2,9]],
		"/(default)/(public)/(statics-core)/[grants=grants]/[dynamic_page_grants]": [~113,[2]],
		"/(default)/(public)/(statics-core)/[documents=documents]/[slug]": [~111,[2]],
		"/(default)/(public)/(product-pages)/[heat_pump=heat_pump]/[dynamic_page_hvac]": [~76,[2,12]],
		"/(default)/(public)/(features-support)/[job_offers=job_offers]/[slug]": [~70,[2]],
		"/(default)/(public)/(features-support)/[glossary=glossary]/[slug]": [~68,[2]],
		"/(default)/(public)/(features-support)/[academy=academy]/[course]": [~61,[2,8]],
		"/(default)/(public)/(features-support)/[academy=academy]/[course]/[registered=registered]": [~62,[2,8]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[category_or_slug]/[[paging=paging]]": [~106,[2,21]],
		"/(default)/(public)/(features-support)/[expert_advice=expert_advice]/[category_or_slug]/[[paging=paging]]": [~65,[2,9]],
		"/(default)/(public)/(statics-core)/[blog=blog]/[category_or_slug]/[slug]": [~107],
		"/(default)/(public)/(features-support)/[expert_advice=expert_advice]/[category_or_slug]/[slug]": [~66],
		"/(default)/(public)/(features-core)/[service=service]/[device]/[[city]]": [~51,[2,3]],
		"/(default)/(public)/[dynamic_page]": [~125,[2]],
		"/(default)/(public)/[dynamic_page]/[dynamic_page_2]": [~126,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';